const Mixin = {
  methods: {
    // Set all fields of an object to the specified value
    resetObject(obj, value) {
      Object.keys(obj).forEach((field) => (obj[field] = value));
    },
  },
};

export { Mixin };
