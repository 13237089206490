import Vue from 'vue';
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';

Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultToastPosition: 'is-bottom',
  defaultToastDuration: 3000,
});
require('@/assets/main.scss');
