<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'Kanban Manager',
    titleTemplate: '%s | Kanban Manager',
  },
};
</script>
