import apiClient from './client.js';

export default {
  /* Authentication */
  auth: {
    async login(username, password) {
      return await apiClient.post('/token', {
        username: username,
        password: password,
      });
    },

    async refreshToken(refresh) {
      return await apiClient.post('/token/refresh', {
        refresh: refresh,
      });
    },

    async changePassword(userId, oldPassword, newPassword) {
      return await apiClient.put(`/users/${userId}/change_password`, {
        old_password: oldPassword,
        new_password: newPassword,
        new_password2: newPassword,
      });
    },

    async changePasswordAdmin(userId, newPassword) {
      return await apiClient.put(`/users/${userId}/change_password_admin`, {
        password: newPassword,
        password2: newPassword,
      });
    },
  },

  // Users
  users: {
    async getUser(id) {
      return await apiClient.get(`/users/${id}`);
    },

    async getUsersSearch(currentPage, pageSize, searchString, field, order) {
      return await apiClient.get(
        `/users?page=${currentPage}&page_size=${pageSize}&order_by=${order}${field}&query=(username__icontains=${searchString}~OR~group__name__icontains=${searchString}~OR~company__name__icontains=${searchString}~OR~first_name__icontains=${searchString}~OR~last_name__icontains=${searchString}~OR~created__icontains=${searchString})`
      );
    },

    async getUsers(currentPage, pageSize) {
      return await apiClient.get('/users', {
        params: {
          page: currentPage,
          page_size: pageSize,
          order_by: 'username',
        },
      });
    },

    async deleteUsers(id) {
      return await apiClient.delete(`/users/${id}`);
    },

    async createUser(
      pwd1,
      pwd2,
      user,
      mail,
      first_name,
      last_name,
      role,
      destination,
      company
    ) {
      if (destination == null && company == null) {
        return await apiClient.post('/users', {
          password: pwd1,
          password2: pwd2,
          username: user,
          email: mail,
          first_name: first_name,
          last_name: last_name,
          is_active: true,
          group: { id: parseInt(role) },
        });
      } else if (destination == null || destination == '') {
        return await apiClient.post('/users', {
          password: pwd1,
          password2: pwd2,
          username: user,
          email: mail,
          first_name: first_name,
          last_name: last_name,
          company: { id: parseInt(company) },
          is_active: true,
          group: { id: parseInt(role) },
        });
      } else if (company == null) {
        return await apiClient.post('/users', {
          password: pwd1,
          password2: pwd2,
          username: user,
          email: mail,
          first_name: first_name,
          last_name: last_name,
          is_active: true,
          destination: { id: destination },
          group: { id: parseInt(role) },
        });
      } else {
        return await apiClient.post('/users', {
          password: pwd1,
          password2: pwd2,
          username: user,
          email: mail,
          first_name: first_name,
          last_name: last_name,
          company: { id: parseInt(company) },
          is_active: true,
          destination: { id: destination },
          group: { id: parseInt(role) },
        });
      }
    },

    async editUser(
      pwd1,
      pwd2,
      user,
      mail,
      first_name,
      last_name,
      role,
      destination,
      company,
      id
    ) {
      if (destination == null && company == null) {
        return await apiClient.patch(`/users/${id}`, {
          password: pwd1,
          password2: pwd2,
          username: user,
          email: mail,
          first_name: first_name,
          last_name: last_name,
          is_active: true,
          group: { id: parseInt(role) },
        });
      } else if (destination == null || destination == '') {
        return await apiClient.patch(`/users/${id}`, {
          password: pwd1,
          password2: pwd2,
          username: user,
          email: mail,
          first_name: first_name,
          last_name: last_name,
          company: { id: parseInt(company) },
          is_active: true,
          group: { id: parseInt(role) },
        });
      } else if (company == null) {
        return await apiClient.patch(`/users/${id}`, {
          password: pwd1,
          password2: pwd2,
          username: user,
          email: mail,
          first_name: first_name,
          last_name: last_name,
          is_active: true,
          destination: { id: destination },
          group: { id: parseInt(role) },
        });
      } else {
        return await apiClient.patch(`/users/${id}`, {
          password: pwd1,
          password2: pwd2,
          username: user,
          email: mail,
          first_name: first_name,
          last_name: last_name,
          company: { id: parseInt(company) },
          is_active: true,
          destination: { id: destination },
          group: { id: parseInt(role) },
        });
      }
    },
  },

  //Groups
  groups: {
    async getGroups() {
      return await apiClient.get('/groups');
    },
  },

  // Orders
  orders: {
    async getOrdersOrder(currentPage, pageSize, searchString) {
      return await apiClient.get(
        `/orders?page=${currentPage}&page_size=${pageSize}&query=(customer__code__icontains=${searchString}~OR~destination__description__icontains=${searchString}~OR~created__icontains=${searchString}~OR~shipping_timestamp__icontains=${searchString})`
      );
    },

    async getOrdersFilter(
      currentPage,
      pageSize,
      searchString,
      client,
      state,
      field,
      order,
      date
    ) {
      if (state == null && client == null) {
        return await apiClient.get(
          `/orders?page=${currentPage}&page_size=${pageSize}&order_by=${order}${field}&query=(customer__code__icontains=${searchString}~OR~customer__description__icontains=${searchString}~OR~destination__description__icontains=${searchString})~AND~(created__date=${date}~OR~shipping_timestamp__date=${date})`
        );
      } else if (state == null) {
        return await apiClient.get(
          `/orders?page=${currentPage}&page_size=${pageSize}&order_by=${order}${field}&query=(customer__code__icontains=${searchString}~OR~customer__description__icontains=${searchString}~OR~destination__description__icontains=${searchString})~AND~(created__date=${date}~OR~shipping_timestamp__date=${date})`
        );
      } else if (client == null) {
        return await apiClient.get(
          `/orders?page=${currentPage}&page_size=${pageSize}&order_by=${order}${field}&query=(state__name=${state})~AND~(customer__code__icontains=${searchString}~OR~customer__description__icontains=${searchString}~OR~destination__description__icontains=${searchString})~AND~(created__date=${date}~OR~shipping_timestamp__date=${date})`
        );
      } else {
        return await apiClient.get(
          `/orders?page=${currentPage}&page_size=${pageSize}&order_by=${order}${field}&query=(state__name=${state}~AND~customer__code=${client})~AND~(customer__code__icontains=${searchString}~OR~state__name__icontains=${searchString}~OR~customer__description__icontains=${searchString}~OR~destination__description__icontains=${searchString})~AND~(created__date=${date}~OR~shipping_timestamp__date=${date})`
        );
      }
    },

    async getOrders(currentPage, pageSize) {
      return await apiClient.get('/orders', {
        params: {
          page: currentPage,
          page_size: pageSize,
        },
      });
    },

    async getOrderState(name) {
      return await apiClient.get(`/order_states?query=(name=${name})`);
    },

    async createOrder(rows, customer, destination) {
      return await apiClient.post('/orders', {
        rows: rows,
        customer: customer,
        destination: destination,
        state: { id: 1 },
      });
    },

    async changeOrderState(id, stateName) {
      let stateId;
      await this.getOrderState(stateName)
        .then((response) => {
          stateId = response.data[0].id;
        })
        .catch((error) => {
          console.log(error);
        });
      console.log(stateId);
      return await apiClient.patch(`/orders/${id}`, { state: { id: stateId } });
    },
  },

  // Items
  items: {
    async getItemsSearch(currentPage, pageSize, searchString, field, order) {
      return await apiClient.get(
        `/items?page=${currentPage}&order_by=${order}${field}&page_size=${pageSize}&query=(active=True)~AND~(code__icontains=${searchString}~OR~description__icontains=${searchString}~OR~created__icontains=${searchString})`
      );
    },

    async getItems(currentPage, pageSize) {
      return await apiClient.get('/items', {
        params: {
          page: currentPage,
          page_size: pageSize,
          active: 'True',
          order_by: 'code',
        },
      });
    },

    async getItem(code) {
      return await apiClient.get('/items', {
        params: { code: code },
      });
    },

    async getCustomerItemsSearch(
      currentPage,
      pageSize,
      searchString,
      field,
      order
    ) {
      return await apiClient.get(
        `/customer_items?page=${currentPage}&order_by=${order}${field}&page_size=${pageSize}&query=(customer__code__icontains=${searchString}~OR~customer__description__icontains=${searchString}~OR~code__icontains=${searchString}~OR~description__icontains=${searchString}~OR~item__code__icontains=${searchString}~OR~item__description__icontains=${searchString})`
      );
    },

    async getCustomerItems(currentPage, pageSize) {
      return await apiClient.get('/customer_items', {
        params: {
          page: currentPage,
          page_size: pageSize,
          order_by: 'code',
          item__active: 'True',
        },
      });
    },

    async getCustomerItem(mainItemCode, customerId) {
      if (customerId) {
        return await apiClient.get(
          `/customer_items?query=(item__code=${mainItemCode}~AND~customer__id=${customerId})`
        );
      }

      return await apiClient.get(
        `/customer_items?query=(item__code=${mainItemCode})`
      );
    },
  },

  // Companies
  companies: {
    async getAllCustomers() {
      return await apiClient.get(
        `/companies?&order_by=name&query=(type__name__icontains=cliente)`
      );
    },

    async getCompanies(currentPage, pageSize) {
      return await apiClient.get('/companies', {
        params: {
          page: currentPage,
          page_size: pageSize,
          order_by: 'name',
        },
      });
    },

    async getCompaniesSearch(
      currentPage,
      pageSize,
      searchString,
      field,
      order
    ) {
      return await apiClient.get(
        `/companies?page=${currentPage}&page_size=${pageSize}&order_by=${order}${field}&query=(code__icontains=${searchString}~OR~name__icontains=${searchString}~OR~type__name__icontains=${searchString}~OR~description__icontains=${searchString}~OR~created__icontains=${searchString})`
      );
    },

    async createCompany(code, name, description, type) {
      return await apiClient.post('/companies', {
        code: code,
        name: name,
        description: description,
        type: type,
      });
    },

    async deleteCompany(id) {
      return await apiClient.delete(`/companies/${id}`);
    },
  },

  // Company_types
  company_type: {
    async getCompanyTypes() {
      return await apiClient.get('/company_types');
    },
  },

  // Destinations
  destinations: {
    async getDestinations(currentPage, pageSize) {
      return await apiClient.get(
        `/destinations?page=${currentPage}&page_size=${pageSize}&order_by=description`
      );
    },

    async getDestinationsCustom(customer) {
      return await apiClient.get(`/destinations?query=(customer=${customer})`);
    },

    async getDestinationsSearch(
      currentPage,
      pageSize,
      searchString,
      field,
      order
    ) {
      return await apiClient.get(
        `/destinations?page=${currentPage}&page_size=${pageSize}&order_by=${order}${field}&query=(id_erp__icontains=${searchString}~OR~customer__name__icontains=${searchString}~OR~customer__code__icontains=${searchString}~OR~description__icontains=${searchString}~OR~created__icontains=${searchString})`
      );
    },

    async createDestinations(id, description, company) {
      return await apiClient.post('/destinations', {
        id_erp: parseInt(id),
        description: description,
        customer: company,
      });
    },

    async deleteDestination(id) {
      return await apiClient.delete(`/destinations/${id}`);
    },
  },
};
