import Vue from 'vue';
import Vuex from 'vuex';
import { Mixin } from '../mixin.js';
import api from '@/services/api.js';
import vuexPersistedState from '@/plugins/vuexPersistedState.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      id: null,
      username: null,
      firstname: null,
      lastname: null,
      email: null,
      company: null,
      destination: null,
      role: null,
      access: null,
      refresh: null,
    },
  },
  getters: {
    currentUser(state) {
      return state.user;
    },
  },
  mutations: {
    setAccessToken(state, token) {
      state.user.access = token;
    },
    setRefreshToken(state, refreshToken) {
      state.user.refresh = refreshToken;
    },
    removeUserData(state) {
      Mixin.methods.resetObject(state.user, null);
    },
    setUserData(state, data) {
      state.user.id = data.id;
      state.user.username = data.username;
      state.user.firstname = data.first_name;
      state.user.lastname = data.last_name;
      state.user.email = data.email;
      state.user.company = data.company;
      state.user.destination = data.destination;
      state.user.role = data.role;
    },
  },
  actions: {
    refreshToken: async ({ state, commit }) => {
      await api.auth
        .refreshToken(state.user.refresh)
        .then((response) => {
          commit('setAccessToken', response.data.access);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  modules: {},
  plugins: [vuexPersistedState],
});
