import axios from 'axios';
import store from '@/store';
import router from '@/router';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
});

// Request interceptor to set the token for every request
apiClient.interceptors.request.use(
  (config) => {
    const token = store.getters.currentUser.access;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers['Accept-Language'] = process.env.VUE_APP_LANG;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor to refresh access token
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // If refresh token is expired
    if (
      error.response.status === 401 &&
      originalRequest.url.includes('/token/refresh')
    ) {
      store.commit('removeUserData');
      router.push({ name: 'Login' });
      return Promise.reject(error);
      // Else if the access token is expired and it's not a retry
    } else if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      await store.dispatch('refreshToken');
      return apiClient(originalRequest);
    }

    return Promise.reject(error);
  }
);

export default apiClient;
