import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

// Dynamic import of views
const AppContainer = () => import('../containers/AppContainer.vue');
const OrdersView = () => import('../views/OrdersView.vue');
const ItemsView = () => import('../views/ItemsView.vue');
const CustomerItemsView = () => import('../views/CustomerItemsView.vue');
const NewOrderView = () => import('../views/NewOrderView.vue');
const NewUserView = () => import('../views/NewUserView.vue');
const NewCompanyView = () => import('../views/NewCompanyView.vue');
const NewDestinationView = () => import('../views/NewDestinationView.vue');
const LoginView = () => import('../views/LoginView.vue');
const LogoutView = () => import('../views/LogoutView.vue');
const ProfileView = () => import('../views/ProfileView.vue');
const UnauthorizedView = () => import('../views/UnauthorizedView.vue');
const UsersView = () => import('../views/UsersView.vue');
const CompaniesView = () => import('../views/CompaniesView.vue');
const DestinationsView = () => import('../views/DestinationsView.vue');
const EditUserView = () => import('../views/EditUserView.vue');
const NotFoundView = () => import('../views/NotFoundView.vue');

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: UnauthorizedView,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: LogoutView,
  },
  {
    path: '/',
    redirect: 'orders',
    component: AppContainer,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: 'orders',
        name: 'Orders',
        component: OrdersView,
        props: true,
      },
      {
        path: 'users',
        name: 'Users',
        component: UsersView,
        props: true,
      },
      {
        path: 'companies',
        name: 'Companies',
        component: CompaniesView,
        props: true,
      },
      {
        path: 'destinations',
        name: 'Destinations',
        component: DestinationsView,
        props: true,
      },
      {
        path: 'new-order',
        name: 'New order',
        component: NewOrderView,
      },
      {
        path: 'new-user',
        name: 'New user',
        component: NewUserView,
      },
      {
        path: 'new-company',
        name: 'New company',
        component: NewCompanyView,
      },
      {
        path: 'new-destination',
        name: 'New destination',
        component: NewDestinationView,
      },
      {
        path: 'items',
        name: 'Items',
        component: ItemsView,
        meta: {
          requireNotCustomer: true,
        },
      },
      {
        path: 'customer-items',
        name: 'Customer items',
        component: CustomerItemsView,
      },
      {
        path: 'profile',
        name: 'Profile',
        component: ProfileView,
      },
      {
        path: 'edit-user',
        name: 'Edit User',
        component: EditUserView,
        props: { id: true },
      },
    ],
  },
  { path: '*', component: NotFoundView },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: 'is-active',
});

/* Check against authentication and authorization */
router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requireAuth) &&
    !store.getters.currentUser.access
  ) {
    next({ name: 'Login', query: { to: to.path } });
  } else {
    if (
      to.matched.some((record) => record.meta.requireNotCustomer) &&
      store.getters.currentUser.role === 'customer'
    ) {
      router.push({
        name: 'Unauthorized',
      });
    } else {
      next();
    }
  }
});

export default router;
